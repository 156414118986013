.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.sidebar-drawer > .MuiDrawer-paper {
    background-color: #f8f8fa !important;
    border: none !important;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.MuiInputBase-input,
.MuiOutlinedInput-input {
    border-radius: 0 !important;
    border: none;
}

main.RaLayout-contentWithSidebar .MuiDrawer-root.MuiDrawer-docked:first-child {
    display: none;
}

.css-1fqs12s > .css-1u2mxbp {
    display: none !important;
}

.css-173zx7o-MuiStack-root {
    margin-top: 20px;
}

.RaLayout-content {
    background: #fff !important;
}

.limit-text {
    overflow: hidden;
    hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
}

#main-content.RaLayout-content {
    width: 100vw !important;
}

.custom-component-container {
    display: flex;
    height: 100%;
}


.custom-component-container .custom-container-label {
    font-size: 11px;
    display: flex;
    align-items: center
}

.waybill-container {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #E5E5E5;
}



.waybill-container .custom-component-container:not(:last-child) {
        border-bottom: 1px solid #E5E5E5;
}


.otp-input-fields {
      margin-right: 10px;
      width: 64px;
      height: 64px;
      text-align: center;
      border:1px solid #D3D6DA;
      border-radius: 16px;
      font-size: 16px;
      font-weight: bold;
}
input[type="file"] > button{
  background-color: white;
}
input::file-selector-button {
    background-color: transparent;
    border: none;
    background-image: url('/public/assets/images/btn-ic.png') !important;
    display: none;
}


.px-1{
    padding-inline: 1rem;
}